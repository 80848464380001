import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import {
  Button,
  Input,
  Loader,
  Message,
  SelectPicker,
  useToaster,
} from "rsuite";
import { TiDelete } from "react-icons/ti";
import Accordion from "react-bootstrap/Accordion";
import api from "../../lib/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Table } from "rsuite";

// import Accordion,{  Placeholder } from 'rsuite';

const RegionsAndDepartmentsMapping = ({ step, setStep }) => {
  const toaster = useToaster();
  const [placement, setPlacement] = React.useState("bottomEnd");

  const { Column, HeaderCell, Cell } = Table;
  // const [regionsArray, setRegionsArray] = useState([]);
  // const [departmentsArray, setDepartmentsArray] = useState([]);
  const [regionId, setRegionId] = useState();
  const [dipartmentId, setDipartmentId] = useState();
  const [fetchDataGetApi, setFetchDataGetApi] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    // defaultValues: {
    //   region: '',
    //   department: '',
    // }
  });

  const addMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Region added successfully"} </strong>
    </Message>
  );

  const deleteMessage = (
    <Message showIcon type={"error"} closable>
      <strong>{"Designation deleted successfully"} </strong>
    </Message>
  );

  const updateMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Region update successfully"} </strong>
    </Message>
  );

  const { data: RDData, isLoading: getAllRegionDepartmentMappingLoading } =
    useQuery(
      ["getAllRegionDepartmentMapping", fetchDataGetApi],
      () =>
        api.get(
          "regionDepartmentMapping/web/getAllRegionDepartmentMapping?pageNo=1&pageSize=1000&isAscending=false&sortBy&filterBy&searchValue"
        ),
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        enabled: true,
        select: (res) => res.data.customResponse.content,
      }
    );

  const { data: regionsArray, isLoading: ipLoadingRegionLoading } = useQuery(
    ["getAllRegionInDepartmentMapping"],
    () =>
      api.get(
        "masterRegion/web/getAllRegion?pageNo=1&pageSize=1000&isAscending=false&sortBy&filterBy&searchValue"
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: true,
      select: (res) => res.data.paginationResponse.content,
    }
  );

  const { data: departmentsArray, isLoading: ipLoadingDepartmentsLoading } =
    useQuery(
      ["getAllDepartmentsAndRegionMapping"],
      () =>
        api.get(
          "masterDepartment/web/getAllDepartments?pageNo=1&pageSize=1000&isAscending=false&sortBy&filterBy&searchValue"
        ),
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        enabled: true,
        select: (res) => res.data.paginationResponse.content,
      }
    );

  const handleRegionAndDepartment = (data) => {
    addRD({
      regionId: regionId,
      departmentId: dipartmentId,
    });
  };

  const { mutate: addRD, isLoading } = useMutation(
    ["addRegionDepartmentMapping"],
    async (body) =>
      await api.post(
        `regionDepartmentMapping/web/addRegionDepartmentMapping`,
        body
      ),
    {
      onSuccess: (res) => {
        setFetchDataGetApi(!fetchDataGetApi);
        setRegionId(0);
        setDipartmentId(0);
        toaster.push(addMessage, { placement, duration: 5000 });
      },
      onError: (err) => {
        console.log("error", err.response);
      },
    }
  );

  const {
    mutate: deleteStructureMapping,
    isLoading: deleteStructureMappingLoading,
  } = useMutation(
    ["deleteStructureMapping", fetchDataGetApi],
    async (body) =>
      await api.delete(
        `regionDepartmentMapping/web/deleteRegionDepartmentMapping/${body}`
      ),
    {
      onSuccess: (res) => {
        setFetchDataGetApi((prev) => !prev);
        toaster.push(deleteMessage, { placement, duration: 5000 });
      },
      onError: (err) => {
        console.log("error", err.response);
      },
    }
  );

  const {
    mutate: CustomerSetUpLogs,
    isLoading: CustomerSetUpLogsRegionLoading,
    refetch: refetchCustomerSetUpLogsRegion,
  } = useMutation(
    ["updateCustomerSetUpLogs"],
    async (body) =>
      await api.put(`masterCustomer/updateCustomerSetUpLogs`, body),
    {
      onSuccess: (res) => {
        toaster.push(updateMessage, { placement, duration: 5000 });
        setStep((prev) => prev + 1);
        // setStep(4);
      },
      onError: (err) => {
        console.log("error", err.response);
      },
    }
  );

  return (
    <div>
      {getAllRegionDepartmentMappingLoading ||
      ipLoadingRegionLoading ||
      deleteStructureMappingLoading ||
      ipLoadingDepartmentsLoading ? (
        <div className="loader">
          {" "}
          <Loader size="sm" colour="#00000" />
        </div>
      ) : (
        ""
      )}
     <div className="login-bg-new-img">
     <div className="login-page-modals">
        <div className="login-inner-modal modal-5">
          <div className="back-btn">
            <a
              href="#"
              className="color-light"
              onClick={() => setStep((prev) => prev - 1)}
            >
              <IoIosArrowBack />
              Back
            </a>
          </div>
          <h3 className="mt-3">Setup Structure Mapping </h3>
          <p className="my-3">Create mapping as per your organisation.</p>

          <div>
            <div className="align-items-end d-flex">
              <div className="container-fluid p-0">
                <div className="row align-items-end">
                  <div className="col-md-5">
                    <div>
                      <label>Regions </label>

                      <SelectPicker
                        data={regionsArray?.map((item) => {
                          return { label: item.name, value: item.id };
                        })}
                        searchable={false}
                        style={{ width: 224 }}
                        placeholder="Select Region"
                        // {...register("region", {
                        //   // required: "Region is Required",
                        // })}
                        value={regionId}
                        onChange={(val) => {
                          setRegionId(val);
                        }}
                      />
                    </div>
                    <span className=" d-block requird-colr">
                      {errors.region && errors.region.message}
                    </span>
                  </div>
                  <div className="col-md-5">
                    <div>
                      <label>Department </label>
                      <SelectPicker
                        data={departmentsArray?.map((item) => {
                          return { label: item.name, value: item.id };
                        })}
                        searchable={false}
                        style={{ width: 224 }}
                        placeholder="Select Department"
                        // {...register("department", {
                        //   // required: "Department is Required",
                        // })}
                        value={dipartmentId}
                        onChange={(val) => {
                          setDipartmentId(val);
                        }}
                      />
                    </div>
                    <span className=" d-block requird-colr">
                      {errors.department && errors.department.message}
                    </span>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <Button
                        className="btn-large"
                        appearance="primary"
                        // type="submit"
                        onClick={() => handleRegionAndDepartment()}
                      >
                        Add New
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="organisation-structure-data">
                <p className="data-text mb-2 mt-4">List of mapping</p>

                <div className="scroll-container">
                  <Table
                    className="modal-table"
                    height={300}
                    data={RDData}
                    autoHeight
                    bordered
                    cellBordered
                  >
                    <Column flexGrow={1} align="center">
                      <HeaderCell>So.no</HeaderCell>
                      <Cell dataKey="id" />
                    </Column>
                    <Column flexGrow={1} align="center">
                      <HeaderCell>Region</HeaderCell>
                      <Cell>{(rowData) => rowData.region.name}</Cell>
                    </Column>
                    <Column flexGrow={1} align="center">
                      <HeaderCell>Department</HeaderCell>
                      <Cell>{(rowData) => rowData.department.name}</Cell>
                    </Column>
                    <Column flexGrow={1} align="end">
                      <HeaderCell></HeaderCell>
                      <Cell className="left-icon">
                        {(rowData) => (
                          <div
                            onClick={() => deleteStructureMapping(rowData.id)}
                          >
                            <TiDelete />
                          </div>
                        )}
                      </Cell>
                    </Column>
                  </Table>
                </div>
              </div>
            </div>

            <div className="text-center">
              <Button
                className="btn-large mt-3 w-75"
                appearance="primary"
                onClick={() =>
                  CustomerSetUpLogs({ level: "regionDepartmentMapping" })
                }
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
     </div>
    </div>
  );
};

export default RegionsAndDepartmentsMapping;
