import React, { useContext, useEffect, useState } from "react";

import { Link, Navigate, useLocation, useOutlet } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";

import { Button, Modal, SelectPicker } from "rsuite";

import Form from "react-bootstrap/Form";

import { IoSearch } from "react-icons/io5";
import { TbLogout } from "react-icons/tb";

import {
  Container,
  Header,
  Sidebar,
  Sidenav,
  Content,
  Navbar,
  Nav,
  Dropdown,
  DOMHelper,
} from "rsuite";

import Footer from "rsuite/Footer";

import { nav } from "../data/nav";

import { navTwo } from "../data/navTwo";

import { HiOutlineChatAlt2, HiUserCircle } from "react-icons/hi";

import AppContent from "./AppContent";
import MultiProgressBar from "./Dashboard/MultiProgressBar";
import { navModule, navOne } from "../data/navModule";
import { navAdminPages } from "../data/navAdminPages";
import { navSetup } from "../data/navSetup";
import { FiLink2 } from "react-icons/fi";
import { Breadcrumb } from "rsuite";

const { getHeight, getWidth } = DOMHelper;

// import { MyContext } from "../App";

const ProtectedLayout = () => {
  const location = useLocation();
  const [breadcrum, setBreadcrum] = useState("");
  const [expand, setExpand] = useState(true);
  const [mobile, setMobile] = useState(false);
  const [mobileExpand, setMobileExpand] = useState(false);
  const [open, setOpen] = useState(false);

  const [recently, setRecently] = useState({
    name: "Overview",
    path: "/dashboard",
  });

  const { user, logout } = useAuth();
  const [newWidth, setNewWidth] = useState();
  const [favourite, setFavourite] = useState({
    name: "configuartion",
    path: "/configuration",
  });

  const outlet = useOutlet();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleResize = () => {
    if (window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    let breadTextArray = location.pathname.split("/");
    let breadText = breadTextArray
      .map((item) => {
        if (item === "") {
          return "Home";
        } else {
          return item.replaceAll("-", " ");
        }
      })
      .join(" > ");
    setBreadcrum(breadText);
  }, [location]);

  // create an event listener
  useEffect(() => {
    if (window.innerWidth < 767) {
      setMobile(true);
    }
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Fetch token from localStorage on component mount
    const newRecently = localStorage.getItem("recently");
    const newFavorite = localStorage.getItem("favorite");
    if (newRecently) {
      setRecently(JSON.parse(newRecently));
    }
    if (newFavorite) {
      setFavourite(JSON.parse(newFavorite));
    }
  }, []);

  // const context = useContext(MyContext);

  if (!user) {
    return <Navigate to="/login" />;
  }

  const handleSetRecently = (name, path) => {
    setRecently({ ...recently, name: name, path: path });
    localStorage.setItem(
      "recently",
      JSON.stringify({ name: name, path: path })
    );
  };

  const handleFavourite = (name, path) => {
    setFavourite({ ...favourite, name: name, path: path });
    localStorage.setItem(
      "favorite",
      JSON.stringify({ name: name, path: path })
    );
  };

  const handleFavourite2 = () => {
    let fav = JSON.parse(localStorage.getItem("recently"));

    console.log("fav", fav?.path);

    handleFavourite(fav?.name, fav?.path);
    // setRecently({ ...recently,});
  };

  const data = [
    "Today",
    "Tommorrow",
    "Yesterday",
    "Last Week",
    "Last Month",
    "Last Year",
  ].map((item) => ({ label: item, value: item }));

  return (
    <>
      <Container>
        <div
          className={`d-flex main ${
            expand ? "sidebar-expanded" : "sidebar-collapsed"
          }`}
        >
          <div className={`${expand ? "" : "toggle"}`}>
            <Container>
              <Sidebar
                style={{ display: "flex", flexDirection: "column" }}
                width={mobileExpand ? 200 : mobile ? 0 : expand ? 200 : 56}
                collapsible

                // onMouseOver={() => {
                //   setExpand(true);
                // }}
                // onMouseOut={() => {
                //   setExpand(false);
                // }}
              >
                <Sidenav
                  expanded={expand}
                  appearance="subtle"
                  style={{
                    width: mobileExpand ? 200 : mobile ? 0 : expand ? 200 : 56,
                    padding: mobile ? 0 : "0px 9px",
                  }}
                >
                  <div className="d-flex align-items-center us-name">
                    <img
                      style={{ marginTop: "10px", maxWidth: "35px" }}
                      src={
                        expand ? "/images/profile.png" : "/images/profile.png"
                      }
                    ></img>

                    {expand ? (
                      <div>
                        <h3>ByeWind</h3>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  {expand ? (
                    <div className="recent-section">
                      <div className="d-flex">
                        <p>Favorites</p>

                        <p>Recently</p>
                      </div>

                      <ul className="m-0">
                        <li>
                          <Link to={favourite?.path}>{favourite?.name}</Link>
                        </li>

                        <li>
                          <Link to={recently?.path}>{recently?.name}</Link>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}

                  {expand ? (
                    <div className="nav-heading">Dashboard</div>
                  ) : (
                    <></>
                  )}

                  <Sidenav.Body>
                    <Nav>
                      {nav.map((item, i) => {
                        if (item.menu) {
                          return (
                            <Nav.Menu
                              key={i}
                              eventKey={i + 1}
                              // trigger="hover"
                              title={item.title}
                              icon={item.icon}
                              // placement="rightStart"
                            >
                              {item.menu.map((item2, j) => {
                                return (
                                  <Nav.Item key={j} eventKey="1">
                                    <Link to={item2.link}>{item2.title}</Link>
                                  </Nav.Item>
                                );
                              })}
                            </Nav.Menu>
                          );
                        } else {
                          return (
                            <Nav.Item key={i} eventKey="2" icon={item.icon}>
                              <Link to={item.link}> {item.title}</Link>
                            </Nav.Item>
                          );
                        }
                      })}
                    </Nav>
                  </Sidenav.Body>

                  {expand ? <div className="nav-heading">Modules</div> : <></>}
                  <Sidenav.Body>
                    <Nav>
                      {navModule.map((item, index) => {
                        if (item.menu) {
                          return (
                            <Nav.Menu
                              key={index}
                              title={item.title}
                              icon={item.icon}
                              eventKey={`navModule${index}`}
                            >
                              {item.menu.map((item2, index2) => {
                                return (
                                  <Nav.Item
                                    key={index2}
                                    eventKey={index2}
                                    onClick={() =>
                                      handleSetRecently(item2.title, item2.link)
                                    }
                                  >
                                    <Link to={item2.link}>{item2.title}</Link>
                                  </Nav.Item>
                                );
                              })}
                            </Nav.Menu>
                          );
                        }
                      })}
                    </Nav>
                  </Sidenav.Body>
                  {expand ? (
                    <div className="nav-heading">Admin Pages</div>
                  ) : (
                    <></>
                  )}
                  <Sidenav.Body>
                    <Nav>
                      {navAdminPages.map((item, index) => {
                        if (item.menu) {
                          return (
                            <Nav.Menu
                              key={index}
                              eventKey={`navAdminPages${index}`}
                              title={item.title}
                              icon={item.icon}
                            ></Nav.Menu>
                          );
                        }
                      })}
                    </Nav>
                  </Sidenav.Body>

                  {expand ? <div className="nav-heading">Setup</div> : <></>}

                  <Sidenav.Body>
                    <Nav>
                      {navSetup.map((item, index) => {
                        if (item.menu) {
                          return (
                            <Nav.Menu
                              key={index}
                              eventKey={`nvaSetup${index}`}
                              // trigger="hover"y
                              title={item.title}
                              icon={item.icon}
                              // placement="rightStart"
                            >
                              {item.menu.map((item2, index2) => {
                                return (
                                  <Nav.Item key={index2} eventKey={index2}>
                                    <Link to={item2.link}>{item2.title}</Link>
                                  </Nav.Item>
                                );
                              })}
                            </Nav.Menu>
                          );
                        } else {
                          return (
                            <Nav.Item
                              key={index}
                              eventKey={index}
                              icon={item.icon}
                            >
                              <Link to={item.link}> {item.title}</Link>
                            </Nav.Item>
                          );
                        }
                      })}
                    </Nav>
                  </Sidenav.Body>
                </Sidenav>
              </Sidebar>
            </Container>
          </div>

          <Container>
            <div
              className="dashboard-wrapper"
              style={{
                overflow: "scroll",
                marginLeft: mobile ? 0 : expand ? 195 : 56,
              }}
            >
              <Header>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 d-flex align-items-center">
                      <div className="row align-items-center w-100">
                        <div className="col-xl-3 col-lg-4 col-md-3">
                          <div className="header-icon">
                            <div className="d-flex">
                              <a
                                href="#"
                                //  onClick={()=>context.setIsSideToggleBar(!context.isSideToggleBar)}

                                onClick={() =>
                                  expand == true
                                    ? setExpand(false)
                                    : setExpand(true)
                                }
                              >
                                <img src="/images/icons/xpend.svg" />
                              </a>

                              <a
                                href="#"
                                onClick={() => {
                                  handleFavourite2();
                                }}
                              >
                                <img src="/images/icons/fvrt.svg" />
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-9 col-lg-8 col-md-8">
                          <div className="page-name d-flex align-items-center">
                            <Link>{breadcrum}</Link>
                            {/* <h3>Dashboard</h3> */}
                            {/* <Breadcrumb.Item href="/">Home</Breadcrumb.Item> */}

                            {/* <span className="mx-4" style={{ color: "#a9a9a9" }}>
                              {">"}
                            </span> */}

                            {/* <h4>Default</h4> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8 col-md-7 d-flex justify-content-end align-items-center">
                      <div className="row align-items-center">
                        <div className="col-md-7 d-flex justify-content-end">
                          <div className="search-bar">
                            <i>
                              <IoSearch />
                            </i>

                            <Form.Control type="email" placeholder="Search" />
                          </div>
                        </div>

                        <div className="col-md-5">
                          <div className="header-icon2">
                            <div className="d-flex justify-content-end align-items-center">
                              <Button onClick={() => setOpen(true)}>
                                Logout
                              </Button>
                              <div style={{ borderRight: "1px solid #d6d6d6" }}>
                                <a href="#">
                                  <img src="/images/icons/notification.svg" />
                                </a>
                              </div>
                              <div>
                                <SelectPicker
                                  data={data}
                                  style={{ width: 224 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Header>



              

              <div className="responsive-header">
                <div className="responsive-header-inner">
                  <div className="container-fluid">
                    <div
                      className="row align-items-center"
                      style={{ background: "#c9dfff", padding: "0px  17px " }}
                    >
                      <div className="col-6">
                        <div className="header-page-name d-flex">
                          <h2 className="text-brown">Dashboard /</h2>
                          <h2>Default</h2>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="search-bar w-100">
                          <i>
                            <IoSearch />
                          </i>

                          <Form.Control type="email" placeholder="Search" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="responsive-header-icons">
                  <a
                    className="text-center"
                    href="#"
                    onClick={() => setMobileExpand((val) => !val)}

                    // onClick={() =>
                    //   expand == true
                    //     ? setExpand(false)
                    //     : setExpand(true)
                    // }
                  >
                    <img src="/images/icons/xpend.png" />
                  </a>

                  <a href="#" className="text-center">
                    <img src="/images/icons/fvrt.png" />
                  </a>
                  <a href="#" className="text-center">
                    <img src="/images/icons/brightness.png" />
                  </a>

                  <a href="#" className="text-center">
                    <img src="/images/icons/trimer.png" />
                  </a>

                  <a href="#" className="text-center">
                    <img src="/images/icons/notification.png" />
                  </a>

                  <a
                    href="#"
                    className="text-center"
                    onClick={() => setMobileExpand((val) => !val)}
                  >
                    <img src="/images/icons/xpend.png" />
                  </a>
                </div>
              </div>
              <div>
                <AppContent />
              </div>
            </div>
          </Container>
        </div>
      </Container>

      {/* <Container>
        <div
          className={`d-flex main ${
            expand ? "sidebar-expanded" : "sidebar-collapsed"
          }`}
        >
          <div className={`sideWrapper${expand ? "" : "toggle"}`}>
            <Container>
              <Sidebar
                style={{ display: "flex", flexDirection: "column" }}
                width={expand ? 200 : 56}
                collapsible

                // onMouseOver={() => {

                //   setExpand(true);

                // }}

                // onMouseOut={() => {

                //   setExpand(false);

                // }}
              >
                <Sidenav expanded={expand} appearance="subtle">
                  <div className="d-flex align-items-center us-name">
                    <img
                      style={{ marginTop: "10px", maxWidth: "35px" }}
                      src={
                        expand ? "/images/profile.png" : "/images/profile.png"
                      }
                    ></img>

                    {expand ? (
                      <div>
                        <h3>ByeWind</h3>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  {expand ? (
                    <div className="recent-section">
                      <div className="d-flex">
                        <p>Favorites</p>

                        <p>Recently</p>
                      </div>

                      <ul className="m-0">
                        <li>Overview</li>

                        <li>Projects</li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}

                  {expand ? (
                    <div className="nav-heading">Dashboard</div>
                  ) : (
                    <></>
                  )}

                  <Sidenav.Body>
                    <Nav>
                      {nav.map((item, i) => {
                        if (item.menu) {
                          return (
                            <Nav.Menu
                              key={i}
                              eventKey={i + 1}
                              trigger="hover"
                              title={item.title}
                              icon={item.icon}
                              placement="rightStart"
                            >
                              {item.menu.map((item2, j) => {
                                return (
                                  <Nav.Item key={j} eventKey="3-1">
                                    <Link to={item2.link}>{item2.title}</Link>
                                  </Nav.Item>
                                );
                              })}
                            </Nav.Menu>
                          );
                        } else {
                          return (
                            <Nav.Item key={i} eventKey="2" icon={item.icon}>
                              <Link to={item.link}> {item.title}</Link>
                            </Nav.Item>
                          );
                        }
                      })}
                    </Nav>
                  </Sidenav.Body>

                  {expand ? <div className="nav-heading">Page</div> : <></>}

                  <Sidenav.Body>
                    <Nav>
                      {navTwo.map((item, i) => {
                        if (item.menu) {
                          return (
                            <Nav.Menu
                              key={i}
                              eventKey={i + 1}
                              trigger="hover"
                              title={item.title}
                              icon={item.icon}
                              placement="rightStart"
                            >
                              {item.menu.map((item2, j) => {
                                return (
                                  <Nav.Item key={j} eventKey="3-1">
                                    <Link to={item2.link}>{item2.title}</Link>
                                  </Nav.Item>
                                );
                              })}
                            </Nav.Menu>
                          );
                        } else {
                          return (
                            <Nav.Item key={i} eventKey="2" icon={item.icon}>
                              <Link to={item.link}> {item.title}</Link>
                            </Nav.Item>
                          );
                        }
                      })}
                    </Nav>
                  </Sidenav.Body>
                </Sidenav>
              </Sidebar>
            </Container>
          </div>

          <Container>
            <div className="dashboard-wrapper" style={{ overflow: "scroll" }}>
              <Header>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-4 d-flex align-items-center">
                      <div className="row align-items-center">
                        <div className="col-md-3">
                          <div className="header-icon">
                            <div className="d-flex">
                              <a
                                href="#"
                                //  onClick={()=>context.setIsSideToggleBar(!context.isSideToggleBar)}

                                onClick={() =>
                                  expand == true
                                    ? setExpand(false)
                                    : setExpand(true)
                                }
                              >
                                <img src="/images/icons/xpend.png" />
                              </a>

                              <a href="#">
                                <img src="/images/icons/fvrt.png" />
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-9">
                          <div className="page-name d-flex align-items-center">
                            <h3>Dashboard</h3>

                            <span className="mx-4" style={{ color: "#a9a9a9" }}>
                              /
                            </span>

                            <h4>Default</h4>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-8 d-flex justify-content-end align-items-center">
                      <div className="row align-items-center">
                        <div className="col-md-8 d-flex justify-content-end">
                          <div className="search-bar">
                            <i>
                              <IoSearch />
                            </i>

                            <img src="/images/icons/s-icon.png" />

                            <Form.Control type="email" placeholder="Search" />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="header-icon2">
                            <div className="d-flex justify-content-end">
                              <a href="#">
                                <img src="/images/icons/brightness.png" />
                              </a>

                              <a href="#">
                                <img src="/images/icons/trimer.png" />
                              </a>

                              <a href="#">
                                <img src="/images/icons/notification.png" />
                              </a>

                              <a href="#">
                                <img src="/images/icons/xpend.png" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Header>
            </div>
          </Container>
        </div>
      </Container>
      <div>
        <AppContent />
      </div> */}

      <div className="mini-modal">
        <Modal open={open} onClose={handleClose}>
          <div className="modal-inner">
            <Modal.Body className="text-center">
              <div className="modal-icon">
                <TbLogout size={25} />
              </div>

              <h3>Logout</h3>
              <p>Are you sure you want to logout ?</p>
            </Modal.Body>

            <div className="d-flex">
              <button
                className="btn btn-primary border"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  logout();
                  setOpen(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default React.memo(ProtectedLayout);
